@import '../vars';

.mat-checkbox {
  font-family: $Mulish;
  display: flex!important;

  &.text-long {
    .mat-checkbox-layout {
      align-items: flex-start;

      .mat-checkbox-label {
        max-width: 535px;
      }
    }
  }

  .mat-checkbox-inner-container {
    display: flex;
    align-items: flex-start;
    width: 24px;
    height: 24px;
    margin: 0 12px 0 0;
  }
  .mat-checkbox-frame {
    border-radius: $border-radius-4;
    border-color: $color-stroke;
  }
  .mat-checkbox-frame,
  .mat-checkbox-layout {
    white-space: break-spaces;

    .mat-checkbox-label {
      text-align: left;
      line-height: 20px;
      cursor: default;
    }
  }
  .mat-checkbox-layout {
    display: flex;
    align-items: center;
  }
  .mat-checkbox-background {
    border-radius: $border-radius-4;
  }
  .mat-checkbox-checkmark {
    width: 16px;
    height: 16px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .mat-checkbox-frame {
      border-color: $color-accent-hover-state;
    }
  }

  &:active {
    .mat-checkbox-frame {
      border-color: $color-accent-click-state;
    }
  }
}
.mat-checkbox-checked.mat-accent {
  .mat-checkbox-background {
    border-radius: $border-radius-4;
    background-color: $color-accent-orange-primary;
  }

  &:hover {
    .mat-checkbox-background {
      background-color: $color-accent-hover-state;
    }
  }

  &:active {
    .mat-checkbox-background {
      background-color: $color-accent-click-state;
    }
  }
}

// employee stepper checkbox
.mat-checkbox {
  &.checkbox_stepper {
    .mat-checkbox-layout {
      .mat-checkbox-label {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;
      }
    }

    // disabled
    // TODO: refactor to default
    &.mat-checkbox-disabled {
      .mat-checkbox-background {
        border: 2px solid $color-text-disabled;
        background-color: $color-bg-disabled;
      }
      .mat-checkbox-layout {
        .mat-checkbox-label {
          color: $color-text-disabled;
        }
      }
    }
  }
}
