@import "../vars";

.rtl {
  text-align: right;

  // layout
  .content {
    margin-left: 0 !important;
    margin-right: 280px;

    &:has(.login),
    &:has(.choice),
    &:has(.WordSection1),
    &:has(.forgot-password__wrapper) {
      margin: 0 auto;
      gap: 50px;
    }
  }

  .mat-checkbox .mat-checkbox-checkmark {
    transform: translate(-30%, -50%);
  }

  .aside {
    left: unset !important;
    right: 0;
  }

  // sidebar
  .exit {
    margin-left: auto;
    margin-right: 24px;
  }

  .logo {
    margin-left: 0;
    margin-right: 12px;

    &__img {
      margin-left: 12px;
    }
  }

  .nav {
    &_link {
      border-radius: $border-radius-10 0 0 $border-radius-10 !important;
    }
  }

  // register
  .btn_back {
    .icon {
      transform: scale(-1);
    }
  }

  // additional form
  .btn_remove_child {
    right: auto !important;
    left: 0;
  }

  .attention {
    .icon {
      right: auto !important;
      left: 16px;
    }
  }

  // file modal
  .file-modal {
    text-align: right;

    .text {
      text-align: right;
    }
  }

  // worker-modal
  .worker-modal {
    align-items: flex-end !important;
  }

  // language-modal
  .language {
    &_title {
      text-align: right;
    }

    &_list {
      flex-direction: row-reverse;
    }

    .icon {
      right: unset !important;
      left: 24px;
    }
  }

  .footer {
    left: 0 !important;
    right: 280px !important;

    h3 {
      font-family: $Mulish;
    }
  }

  .title {
    text-align: right !important;
  }

  .title-group_title {
    text-align: right !important;
  }

  // Stepper

  .step {
    &:not(:last-of-type) {
      &::after {
        right: 50%;
      }
    }

    &.active {
      &::after {
        right: 50%;
      }
    }

    &:not(:last-of-type):first-of-type {
      &::after {
        right: 50%;
      }
    }
  }

  // Job Cards

  .approve-gig {
    &__avatar {
      margin: 0 0 0 5px !important;
    }
  }

  // Tabs

  .avatar,
  .mock-avatar {
    margin: 0 0 0 5px !important;
  }

  .job-header__back-icon {
    rotate: 180deg;
    font-size: 20px !important;
  }
}
