@import '../vars';

// job-details
.mat-tab-body-wrapper {
  width: 100%;
}

.mat-tab-body {
  .mat-tab-group {
    font-family: $Mulish;
  }
}

table.mat-table {
  width: 100%;
  font-family: $Mulish;

  thead {
    width: 100%;
  }

  tr.mat-header-row {
    display: flex;
    gap: 32px;
    height: 32px;
    border-bottom: 1px solid $color-gray-60;
  }

  tr.mat-row {
    position: relative;
    display: flex;
    gap: 32px;
    height: auto;
    cursor: pointer;
    transition: $transition-default;
    z-index: $z-index-90;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-dividers;
    }

    // &::before {
    //   position: absolute;
    //   top: -1px;
    //   left: -5%;
    //   content: "";
    //   height: 101%;
    //   width: 1057px;
    //   background-color: $color-bg-aqua;
    //   z-index: $z-index--1;
    //   opacity: 0;
    //   transition: $transition-default;
    // }

    &:hover {
      border-bottom-color: $color-bg-aqua;

      &::before {
        opacity: 1;
      }

      // worker tr menu
      .menu {
        opacity: 1;
      }
    }
  }

  th.mat-header-cell {
    letter-spacing: $spacing--001;
    line-height: 16px;
    font-size: 13px;
    font-weight: $weight-500;
    font-family: $Mulish;
    color: $color-text-tertiary;
    border-bottom: none;
  }

  th.mat-header-cell:first-of-type {
    padding-left: 0;
  }

  td.mat-cell {
    display: flex;
    align-items: center;
    // padding: 24px 0;
    height: 88px;
    border-bottom: none;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    .avatar {
      margin-right: 12px;
      min-width: 40px;
    }

    &.name {
      letter-spacing: $spacing-1;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }

    &.date {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      letter-spacing: $spacing--001;
      line-height: 16px;
      font-size: 13px;
      color: $color-text-primary;
      font-weight: $weight-500;

      .date_start {
        margin-bottom: 2px;
      }
    }

    &.status {

      &.waiting,
      &.started,
      &.working,
      &.not-started,
      &.completed,
      &.expectation &.overdue {
        span {
          padding: 4px 8px;
          letter-spacing: $spacing--001;
          line-height: 16px;
          font-size: 13px;
          font-weight: $weight-500;
          border-radius: $border-radius-5;
          border: 1px solid transparent;
        }
      }

      &.waiting span {
        color: #FAD201;
        background-color: $color-attention-tertiary;
        border-color: $color-attention-hover;
      }

      &.started span,
      &.working span {
        color: $color-successful;
        background-color: $color-successful-tertiary;
        border-color: $color-successful;
      }

      &.not-started span {
        color: $color-error;
        background-color: $color-error-tertiary;
        border-color: $color-error;
      }

      &.completed span {
        color: $color-purple;
        background-color: $color-purple-tertiary;
        border-color: $color-purple;
      }

      &.expectation span {
        padding: 4px 8px;
        color: $color-accent-orange-primary;
        background-color: $color-accent-orange-quaternary;
        border-color: $color-accent-orange-primary;
      }

      &.overdue span {
        color: $color-error;
        border-color: $color-error;
        background-color: $color-error-tertiary;
      }
    }

    &.worked_out {
      letter-spacing: $spacing--001;
      line-height: 16px;
      font-size: 13px;
      color: $color-text-primary;
      font-weight: $weight-500;
    }
  }

  td.mat-cell:first-of-type {
    padding-left: 0;
  }
}

// job-performers
table.mat-table {
  &.job-performers {

    tr.mat-header-row,
    tr.mat-row {
      gap: 32px;
    }

    // width of each header & body cell
    th.mat-header-cell,
    td.mat-cell {
      &.name {
        width: 252px;
      }

      &.status {
        width: 168px;
      }

      &.worked_out {
        width: 84px;
      }

      &.date {
        width: 150px;
      }

      &.actions {
        width: 150px;
      }

      // width job-list table
      &.title {
        width: 252px;
      }

      &.job-status {
        width: 80px;
      }

      &.hourly-pay {
        width: 100px;
      }

      &.daily-pay {
        width: 100px;
      }

      &.job-date {
        width: 150px;
      }

      &.performer {
        width: 61px;
      }
    }
  }
}

// job-list
table.mat-table {
  tr.mat-header-row {
    gap: 18px;
  }

  tr.mat-row {
    gap: 26px;
  }

  td.mat-cell {
    &.title {
      letter-spacing: $spacing-1;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
      line-height: 33px; // indeed for ellipsis
      display: inline-block; // indeed for ellipsis
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &.status {
      text-transform: capitalize;

      &.active,
      &.open,
      &.completed,
      &.pending {
        span {
          padding: 4px 8px;
          letter-spacing: $spacing--001;
          line-height: 16px;
          font-size: 13px;
          font-weight: $weight-500;
          border-radius: $border-radius-5;
          border: 1px solid transparent;
        }
      }

      &.active span {
        color: $color-successful-hover;
        background-color: $color-successful-tertiary;
        border-color: $color-successful-hover;
      }

      &.open span {
        color: $color-blue;
        background-color: $color-blue-secondary;
        border-color: $color-blue;
      }

      &.completed span {
        color: $color-purple;
        background-color: $color-purple-tertiary;
        border-color: $color-purple;
      }

      &.pending span {
        color: $color-attention-hover;
        background-color: $color-attention-tertiary;
        border-color: $color-attention-hover;
      }

      &.overdue span {
        color: $color-error;
        border-color: $color-error;
        background-color: $color-error-tertiary;
      }
    }

    &.payment,
    &.category,
    &.performer {
      letter-spacing: $spacing-1;
      font-size: 15px;
      font-weight: $weight-500;
      color: $color-text-primary;
    }

    &.worked_out {
      letter-spacing: $spacing--001;
      line-height: 16px;
      font-size: 13px;
      color: $color-text-primary;
      font-weight: $weight-500;
    }
  }

  td.mat-cell:first-of-type {
    padding-left: 0;
  }

  // width of each header & body cell
  // th.mat-header-cell,
  // td.mat-cell {
  //   &.title {
  //     width: 289px;
  //   }

  //   &.status {
  //     width: 80px;
  //   }

  //   &.payment {
  //     width: 90px;
  //   }

  //   &.category {
  //     width: 130px;
  //   }

  //   &.date {
  //     width: 130px;
  //   }

  //   &.performer {
  //     width: 61px;
  //   }
  // }

  // header override
  th.mat-header-cell {
    &.title {
      width: 297px;
    }

    &.status {
      width: 88px;
    }

    &.payment {
      width: 98px;
    }

    &.category {
      width: 140px;
    }
  }
}

// job-request-list
table.mat-table {
  &.job-request-list {
    tr.mat-header-row {
      gap: 20px;
    }

    tr.mat-row {
      gap: 20px;
    }

    td.mat-cell {
      &.name {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;
        line-height: 33px; // indeed for ellipsis
        display: inline-block; // indeed for ellipsis
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.status {
        text-transform: capitalize;

        &.accepted,
        &.wait,
        &.declined,
        &.new {
          span {
            padding: 4px 8px;
            letter-spacing: $spacing--001;
            line-height: 16px;
            font-size: 13px;
            font-weight: $weight-500;
            border-radius: $border-radius-5;
            border: 1px solid transparent;
          }
        }

        &.accepted span,
        &.new span {
          color: $color-successful-hover;
          background-color: $color-successful-tertiary;
          border-color: $color-successful-hover;
        }

        &.wait span {
          color: $color-attention-hover;
          background-color: $color-attention-tertiary;
          border-color: $color-attention-hover;
        }

        &.declined span {
          color: $color-error;
          background-color: $color-error-tertiary;
          border-color: $color-error;
        }

        &.overdue span {
          color: $color-error;
          border-color: $color-error;
          background-color: $color-error-tertiary;
        }
      }

      &.phone,
      &.hours {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        color: $color-text-primary;
        font-weight: $weight-500;
      }
    }

    td.mat-cell:first-of-type {
      padding-left: 0;
    }

    // width of each header & body cell
    th.mat-header-cell,
    td.mat-cell {
      &.name {
        width: 252px;
        display: flex;
      }

      &.status {
        width: 260px;
      }

      &.phone {
        width: 247px;
      }

      &.hours {
        width: 114px;
      }
    }

    th.mat-header-cell:last-of-type {
      padding-right: 0;
    }
  }
}

// worker-details-modal
table.mat-table {
  &.job-worker-modal {

    tr.mat-header-row,
    tr.mat-row {
      gap: 32px;
    }

    tr.mat-row {
      cursor: default;
    }

    // width of each header & body cell
    th.mat-header-cell,
    td.mat-cell {
      &.title {
        width: 345px;
      }

      &.status {
        width: 322px;
      }

      &.worked_out {
        width: 191px;
      }
    }

    tr.mat-row {
      &::before {
        display: none;
      }
    }

    td.mat-cell {
      &.title {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;
        line-height: 33px; // indeed for ellipsis
        display: inline-block; // indeed for ellipsis
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      &.status {
        text-transform: capitalize;

        span {
          padding: 4px 8px;
          letter-spacing: $spacing--001;
          line-height: 16px;
          font-size: 13px;
          font-weight: $weight-500;
          border-radius: $border-radius-5;
          border: 1px solid transparent;
        }

        &.active span {
          color: $color-successful-hover;
          background-color: $color-successful-tertiary;
          border-color: $color-successful-hover;
        }

        &.completed span {
          color: $color-purple;
          background-color: $color-purple-tertiary;
          border-color: $color-purple;
        }
      }

      &.worked-out {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-primary;
      }
    }
  }
}

// employers table
.table.mat-table {
  &.employers {

    th.mat-header-cell,
    td.mat-cell {
      &.organization-name {
        width: 252px;
      }

      &.contact-name {
        width: 200px;
      }

      &.contacts {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 200px;
      }

      &.number {
        width: 210px;
      }
    }

    td.mat-cell {
      &.contacts {
        justify-content: center;
      }

      &.organization-name,
      &.contact-name,
      &.contacts .contact-phone,
      &.number {
        letter-spacing: $spacing-1;
        font-size: 15px;
        font-weight: $weight-500;
        color: $color-text-primary;
      }

      &.contacts .contact-email {
        letter-spacing: $spacing--001;
        line-height: 16px;
        font-size: 13px;
        font-weight: $weight-500;
        color: $color-text-tertiary;
      }
    }
  }
}
