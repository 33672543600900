@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&family=Roboto:wght@400;500;700&display=swap");
@import "./app/shared/styles/reset";
@import "./app/shared/styles/vars";
@import "app/shared/styles/material/material";
@import "./app/shared/styles/direction/rtl";
@import './app/shared/styles/basic';

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $Mulish, $Roboto, "Helvetica Neue", sans-serif;
  line-height: $line-height;
  font-size: $font-size;
  color: $color-dark;
}

// Now it will be hidden
div.right {
  display: none !important;
}