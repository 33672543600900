@mixin mat-form-field-label-default {
  font-family: 'Nunito Sans', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #5C5C5C;
  line-height: 1.28em;

  @media (max-width: 425px) {
    font-weight: 700;
  }
}

mat-form-field {
  width: 100%;
  // height: 100%;
  user-select: none;

  &.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      // for inner forms
      background-color: #EBF4F4;

      align-items: center;
      padding: 9px 15px 9px 15px;
      transition: 0.2s ease;
      border: 1px solid #EBF4F4;
    }
  }
}


mat-form-field {
  &.ng-invalid.ng-touched.mat-form-field-appearance-fill {
    .mat-form-field-flex {
      background-color: #FFF2F2;
    }
  }

  &.ng-invalid.ng-touched {
    label.mat-form-field-label {
      background-color: #FFF2F2;
    }
  }
}



.mat-form-field-invalid {
  input {
    background-color: #FFF2F2;
  }

  mat-label {
    color: #f44336;
  }
}

.mat-form-field-wrapper {
  height: 50px;
  padding: 0;
}

.mat-form-field-appearance-fill div.mat-form-field-subscript-wrapper {
  bottom: -16px;
  top: unset;
  padding: 0;
}

mat-form-field.with-icon.mat-form-field-appearance-fill div.mat-form-field-flex {
  padding-right: 40px;
}

mat-form-field.mat-focused.mat-form-field-appearance-fill .mat-form-field-flex {
  border: 1px solid #D7D7D7;
  border-radius: 10px;
  // TODO: for inner forms only
  background-color: #EBF4F4;
}

@keyframes label-scale {
  0% {
    top: auto;
  }

  100% {
    top: 0;
  }
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
.mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
  transform: translateY(-100%);
  font-size: 12px;
  line-height: 1.33em;
  width: 100%;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float textarea+.mat-form-field-label-wrapper>.mat-form-field-label {
  transform: translateY(-100%);
  font-size: 12px;
  line-height: 1.5em;
  width: 100%;
}

.mat-focused.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-select.mat-select-empty+.mat-form-field-label-wrapper>.mat-form-field-label {
  transform: translateY(-50%);
  // @include mat-form-field-label-default;
}

span.mat-form-field-label-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  overflow: visible;
  padding: 0;

  &.mat-datepicker-input {
    top: -4px;
  }
}

mat-form-field.mat-form-field-appearance-fill div.mat-form-field-flex {
  border-radius: 8px;
  height: 100%;
}

mat-form-field.mat-form-field-appearance-fill .mat-form-field-infix {
  padding: 0;
  // display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
  border-top: unset;
}

span.mat-form-field-label-wrapper label.mat-form-field-label {
  left: unset;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: auto;

  margin-top: 0;

  // @include mat-form-field-label-default;

  transition: cubic-bezier(0.25, 0.8, 0.25, 1) .2s;
}

textarea+span.mat-form-field-label-wrapper label.mat-form-field-label {
  top: 18px;
  // for inner forms
  background-color: transparent
}

//mat-select:not(.mat-select-empty) + span.mat-form-field-label-wrapper label.mat-form-field-label,
//.mat-input-element:not([placeholder]) + span.mat-form-field-label-wrapper label.mat-form-field-label {
//  line-height: 140%;
//}
span.mat-select-value-text {
  position: relative;
}

input.mat-input-element {
  position: relative;
  max-width: 100%;
  margin: 0;
}

.height-100 {

  .mat-form-field-wrapper {
    height: 100%;
    padding: 0;
  }

  textarea {
    // TODO: fix !important
    padding: 8px 2px 8px 2px !important;
    position: relative;
    top: 20px;
  }
}
